<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.documents") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="grid gap-4">
      <div class="card">
        <custom-table
          :fields="fields"
          :items="documents"
          :loading="waiting"
          :pagination="true"
          :perPage="10"
          :searchable="true"
          :searchFields="searchFields"
          :showHeader="true"
          sortBy="name"
          :sortFields="sortFields"
          @row-click="(id) => router.push(`/documents/${id}`)"
        >
          <template #header>
            <span class="flex justify-end"
              ><form-button
                :command="() => router.push('/documents/create')"
                :icon="PlusIcon"
                id="header-document-create-button"
                label="create_document"
                :textVariant="company.primaryText"
                type="button"
                :variant="company.primary"
            /></span>
          </template>
          <template #cell(classificationId)="{ value }">
            {{
              t(
                `classifications.${getClassificationName(
                  value,
                  classifications
                )}`
              )
            }}
          </template>
          <template #cell(estate)="{ value }">
            {{ value ? value.name : null }}
          </template>
          <template #cell(createdAt)="{ value }">
            {{ getLocaleDate(locale, value) }}
          </template>
          <template #cell(updatedAt)="{ value }">
            {{ getLocaleDate(locale, value) }}
          </template>
          <template #cell(status)="{ value }">
            {{ t(`status.${value}`) }}
          </template>
        </custom-table>
      </div>
      <div class="card">
        <custom-table
          :fields="fields"
          :items="templates"
          :loading="waiting"
          :pagination="true"
          :perPage="10"
          :searchable="true"
          :searchFields="searchFields"
          :showHeader="true"
          sortBy="name"
          :sortFields="sortFields"
          @row-click="(id) => router.push(`/documents/${id}`)"
        >
          <template #header>
            <span class="flex justify-end"
              ><form-button
                :command="() => router.push('/documents/create')"
                :icon="PlusIcon"
                id="header-template-create-button"
                label="create_template"
                :textVariant="company.primaryText"
                type="button"
                :variant="company.primary"
            /></span>
          </template>
          <template #cell(classificationId)="{ value }">
            {{
              t(
                `classifications.${getClassificationName(
                  value,
                  classifications
                )}`
              )
            }}
          </template>
          <template #cell(estate)="{ value }">
            {{ value ? value.name : null }}
          </template>
          <template #cell(createdAt)="{ value }">
            {{ getLocaleDate(locale, value) }}
          </template>
          <template #cell(updatedAt)="{ value }">
            {{ getLocaleDate(locale, value) }}
          </template>
        </custom-table>
      </div>
    </div>
  </div>
</template>

<script>
import { PlusIcon } from "@heroicons/vue/24/solid";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import CustomTable from "../components/table/CustomTable.vue";
import { getClassificationName } from "../utils/classificationUtils";
import { getLocaleDate } from "../utils/dateUtils";
export default {
  components: { Breadcrumb, CustomTable, FormButton, PlusIcon },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { locale, t } = useI18n();

    const fields = ref([
      {
        key: "name",
        label: "name",
      },
      {
        key: "classificationId",
        label: "type",
      },
      {
        key: "estate",
        label: "estate_name",
      },
      {
        key: "createdAt",
        label: "created_at",
      },
      {
        key: "updatedAt",
        label: "updated_at",
      },
      {
        key: "status",
        label: "status",
      },
    ]);
    const searchFields = ref(["createdAt", "name", "updatedAt"]);
    const sortFields = ref(["name", "createdAt", "updatedAt"]);

    const classifications = computed(
      () => store.state.document.classifications
    );
    const company = computed(() => store.state.company.company);
    const documents = computed(() => store.state.document.documents);
    const templates = computed(() => store.state.document.templates);
    const user = computed(() => store.state.authentication.user);

    const waiting = computed(() => {
      if (store.state.document.waiting) return true;
      if (store.state.company.waiting) return true;
      if (store.state.authentication.waiting) return true;
      return false;
    });

    onMounted(() => {
      if (!classifications.value.length) {
        store.dispatch("document/getClassifications");
      }
      if (!documents.value.length) {
        store.dispatch("document/getDocuments", user.value.companyId);
      }
      if (!templates.value.length) {
        store.dispatch("document/getTemplates", user.value.companyId);
      }
    });

    return {
      classifications,
      company,
      documents,
      fields,
      getClassificationName,
      getLocaleDate,
      locale,
      PlusIcon,
      router,
      searchFields,
      sortFields,
      t,
      templates,
      waiting,
    };
  },
};
</script>
